/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Stack } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { selectTripsFilterParams } from '../../../redux/slices/trips/trips';
import TripPlannerTripsCard from './TripPlannerTripsCard';
import { Driver, Group, Tag } from '../../../redux/models/settings.models';
import { createDropdownOptions } from '../../../utils/core.utils';
import { BoardSettings } from '../../../redux/models/board.models';
import TripPlannerDriverCard from './TripPlannerDriverCard';
import { TripBoardModel } from '../../../redux/models/trip.models';
import { EntityContainer } from '../../../redux/models/core.models';
import { DriverAvailability } from '../../../redux/models/driver.availability.models';
import { TripPlan } from '../../../redux/slices/planner/planner.models';
import {
  optimisePlan,
//  resetFilters,
 saveDraft,
 selectDriverFilters,
 setCurrentPlan,
 updateDraftDriver,
} from '../../../redux/slices/planner/planner';
import useAppDispatch from '../../../hooks/useAppDispatch';
import ResetPlanButton from './components/ResetPlanButton';
import { findDriverAvailability, sortPlannedDrivers } from '../../../redux/slices/planner/planner.utils';
import DropWrapper from './components/DropWrapper';
import { ItemModel } from './components/ItemModel';
import AsyncButton from '../../../components/shared/buttons/AsyncButton';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import Analytics from '../../../utils/analytics';
import TripPlannerFilters from './TripPlannerFilters';
import ConfirmModal from '../../../components/modals/ConfirmModal';

const tripsFilter = (driverId: string, plan: TripPlan) => {
  const plans = plan.data.plans || {};
  const assigned = plans[driverId] && plans[driverId].length > 0;
  return assigned;
};

const avFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

const tagFilter = (driverId: string, date: string, availability: DriverAvailability[]) => {
  return findDriverAvailability(driverId, date, availability) !== undefined;
};

interface Props {
  availability: DriverAvailability[];
  boardSettings: BoardSettings;
  drivers: Driver[];
  groups: Group[];
  plan: TripPlan;
  tags: Tag[];
  tripContainer: EntityContainer<TripBoardModel>;
}

export default function TripPlannerContainer({
  availability, groups, drivers, boardSettings, plan, tripContainer, tags
}: Props) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const { optimise_planner: optimisePlanner } = useFeatureFlags();
  const [isOptimising, setIsOptimising] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [confirmOptimise, setConfirmOptimise] = useState<string|null>(null);
  const [optimisedPlan, setOptimisedPlan] = useState<TripPlan | null>(null);
  const filters = useSelector(selectTripsFilterParams);
  const selectedDriverFilter = useSelector(selectDriverFilters);
  const {
    available,
    numTrips,
    tagFilters,
    outsideCartage
  } = selectedDriverFilter;
  const isTagFilters = Object.values(tagFilters).find((filter) => filter);
  const driverOptions = createDropdownOptions(drivers, 'samsara_name');
  const isOptmiseEnabled = optimisePlanner?.is_enabled || false;
  const unSortedData = drivers.filter((option) => {
    const driverId = option.entity_id;
    const driverFilters: boolean[] = [];
    const isSelected = selectedDriverFilter.selected[driverId] || false;
    const isHidden = selectedDriverFilter.hidden[driverId] || false;
    if (isHidden) return false;
    if (numTrips) driverFilters.push(tripsFilter(driverId, plan));
    if (available) driverFilters.push(avFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (isTagFilters) driverFilters.push(tagFilter(driverId, filters.dateFrom, availability));
    if (outsideCartage) driverFilters.push(option.data.outside_cartage);
    if (isSelected) driverFilters.push(isSelected);
    return driverFilters.find((flt) => flt);
  });
  const driverData = sortPlannedDrivers(filters.dateFrom, unSortedData, availability);
  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleCancelOptimise = async () => {
    setConfirmOptimise(null);
    setOptimisedPlan(null);
  };
  const handleConfirmOptimise = async () => {
    if (optimisedPlan) {
      await dispatch(setCurrentPlan(optimisedPlan));
    }
    handleCancelOptimise();
  };
  const handleNetworkOptimise = async (greedy: boolean) => {
    try {
      setIsOptimising(true);
      const driverIds = unSortedData.map((dr) => dr.entity_id);
      // const draftResponse = await saveDraft(plan);
      // if (draftResponse.status === 200) {
      //   const initDraft: TripPlan = draftResponse.data.data;
      //   dispatch(setCurrentPlan(initDraft));
      // }
      const response = await optimisePlan(plan, driverIds, greedy);
      if (response.status === 200) {
        const optimised = response.data.data;
        console.log(optimised);
        if (optimised.status && optimised.status === 'success') {
          setConfirmOptimise(optimised.message);
          setOptimisedPlan(optimised.plan);
          console.log(optimised.message);
          dispatch(setCurrentPlan(optimised.plan));
        }
      }
    } catch (error) {
      Analytics.capture(error);
      toast("Couldn't optimise trips", { type: 'error' });
    } finally {
      setIsOptimising(false);
      Analytics.optimiseDispatch(orgCode, greedy);
    }
  };
  // const handleResetFilters = () => {
  //   dispatch(resetFilters());
  // };
  const onDrop = (item: ItemModel, _: any, driverId: string) => {
    dispatch(updateDraftDriver(driverId, item.trip));
  };
  return (
    <>
      <Stack direction="horizontal" className="mb-3 justify-content-between" gap={3}>
        <Stack direction="horizontal" gap={3}>
          {isOptmiseEnabled && (
            <AsyncButton
              title="Assign"
              id="bg-vertical-dropdown-1"
              variant="outline-primary"
              spinner="primary"
              disabled={isOptimising}
              handleClick={() => handleNetworkOptimise(false)}
            />
          )}
          {/* <Button type="button" variant="outline-secondary" onClick={handleResetFilters}>Reset Filters</Button> */}
          <ResetPlanButton plan={plan} trips={tripContainer} />
          {confirmOptimise && (
            <ConfirmModal
              title="Confirm Assign"
              details={confirmOptimise}
              btnTitle="Proceed"
              saveDisabled={false}
              shouldShowModal
              isSaving={false}
              handleSave={handleConfirmOptimise}
              cancelSave={handleCancelOptimise}
            />
          )}
        </Stack>
      </Stack>
      <Row>
        <DndProvider backend={HTML5Backend}>
          <Col md={6}>
            <TripPlannerDriverCard
              data={driverData}
              availability={availability}
              driverOptions={driverOptions}
              boardSettings={boardSettings}
              plan={plan}
              showFilters={showFilters}
              tags={tags}
              handleDrop={onDrop}
              handleShowFilters={handleShowFilters}
            />
          </Col>
          <Col md={6}>
            {showFilters ? (
              <TripPlannerFilters
                availability={availability}
                boardSettings={boardSettings}
                drivers={drivers}
                filters={selectedDriverFilter}
                plan={plan}
              />
            ) : (
              <DropWrapper
                driverId=""
                onDrop={onDrop}
              >
                <TripPlannerTripsCard
                  groups={groups}
                  drivers={driverOptions}
                  plan={plan}
                />
              </DropWrapper>
            )}
        </Col>
        </DndProvider>
      </Row>
    </>
  );
}
