/* eslint-disable */
import { useEffect, useState } from "react"
import { Row, Col, Form } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { AppState } from "../../../redux/models/state.models"
import { selectBoard } from "../../../redux/slices/settings"
import AssetTrackerCol from "./AssetTrackerCol"
import { LocationType } from "../models"

export default function AssetTrackerBoard() {
  const { boardId } = useParams();
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const [searchTerm, setSearchTerm] = useState("");
  const [locationType, setLocationType] = useState(LocationType.lastMove);
  // const [groupBy, setGroupBy] = useState("status")
  const addresses = board?.data.addresses || [];

  // const filteredAssets = assets.filter((asset) =>
  //   asset.name.toLowerCase().includes(searchTerm.toLowerCase())
  // )

  return (
    <div className="min-vh-100">
      <h3>{board?.data.name || 'No name'}</h3>
      <Row className="mb-4">
        <Col xs={12} md={6} lg={4}>
          <Form.Select value={locationType} onChange={(e) => setLocationType(e.target.value)}>
            <option value={LocationType.lastMove}>Load Last Move</option>
            <option value={LocationType.lastLocation}>Load Last Location</option>
          </Form.Select>
        </Col>
        <Col xs={12} md={6} lg={4} className="mb-3 mb-md-0">
          <Form.Control
            type="text"
            placeholder="Search assets..."
            value={searchTerm}
            hidden
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      {addresses.length > 0 && (
        <Row className="flex-nowrap overflow-auto">
          {addresses.map((address) => {
            return (
              <AssetTrackerCol
                key={address.entity_id}
                address={address}
                locationType={locationType}
              />
            )
          })}
        </Row>
      )}
      {addresses.length === 0 && (
        <p className="text-center text-muted mt-5">No addresses configured on the board.</p>
      )}
    </div>
  )
}