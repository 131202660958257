import * as React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <div />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
}

export default AuthGuard;
