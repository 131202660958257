/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { TripTableColumns } from '../../../../../redux/models/board.models'
import ShipmentCell from '../cells/ShipmentCell';
import { ArrowRightCircle } from 'react-feather';
import { Stack } from 'react-bootstrap';
import { Shipment, ShipmentListView } from '../../../../../redux/models/shipment.models';
import { useSelector } from 'react-redux';
import { otherUpdateShipmentListViewItem, selectShipmentEntity, selectShipmentFilterParams, updateShipmentListViewItem } from '../../../../../redux/slices/shipments';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { selectBillingItemsObject, selectShipmentBoardSettings, selectShippingLinesObject, selectUomObject } from '../../../../../redux/slices/settings';
import { deepEqual } from '../../../../../utils/core.utils';
import { errorToast } from '../../../../../components/notifications/app-toast';
import { getDataGridPostBody } from '../../shipment.datagrid.utils';

interface OtherProps {
  shipment: ShipmentListView;
  data: Shipment | null;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  columns: TripTableColumns[];
}

function OtherShipmentRow({
  shipment, data, onUpdate, onSelect, columns, selectedShipments, onOpen, onInvoice, onCreateTrip
}: OtherProps) {
  const dispatch = useAppDispatch();
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const [entity, setEntity] = useState(data);
  const filters = useSelector(selectShipmentFilterParams);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const shippingLines = useSelector(selectShippingLinesObject);
  const billingItems = useSelector(selectBillingItemsObject);
  const uoms = useSelector(selectUomObject);
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const handleUpdate = async (
    shipmentId: string, key: string, value: any
  ) => {
    if (entity) {
      const newData = getDataGridPostBody(
        entity, key, value, boardSettings.billingTypes, billingItems, shippingLines, uoms
      );
      const isEqual = deepEqual(entity, newData);
      if (isEqual) {
        return;
      }
      try {
        const response = await otherUpdateShipmentListViewItem(newData);
        if (response.status === 200) {
          const newEnt: Shipment = response.data.data;
          const newBr = newEnt.data.base_rate;
          const newUom = newBr?.uom;
          const newBtId = newEnt.data.billing_type?.entity_id || '';
          if (newBr && newUom) {
            const mappedUom: Shipment = {
              ...newEnt,
              data: {
                ...newEnt.data,
                base_rate: {
                  ...newBr,
                  uom_id: newUom.entity_id,
                }
              }
            };
            if (newBtId !== '') {
              mappedUom.data.billing_type_id = newBtId;
            }
            setEntity(mappedUom);
            dispatch(updateShipmentListViewItem(mappedUom, filters, boardSettings));
          } else {
            setEntity(newEnt);
            dispatch(updateShipmentListViewItem(newEnt, filters, boardSettings));
          }
          
        }
        if (response.status >= 400) {
          errorToast('Error updating shipment');
        }
      } catch (error: any) {
        if (error.description) {
          errorToast(`Error updating shipment. ${error.description}`);
        } else {
          errorToast('Error updating shipment.');
        }
      }
    }
  }
  const handleOpen = () => {
    navigate(`/${orgCode}/shipments/${shipment.id}`);
  }
  useEffect(() => {
    setEntity(data);
  }, [data]);
  return (
    <>
      <tr>
        {columns.map((column, idx) => {
          if (idx === 1) {
            return (
              <td
                style={{ width: column.width }}
                key={column.key}
                onMouseEnter={() => setIsMenuShowing(true)}
                onMouseLeave={() => setIsMenuShowing(false)}
              >
                <Stack direction="horizontal" gap={2}>
                  <ShipmentCell
                    key={column.key}
                    column={column}
                    shipment={shipment}
                    selectedShipments={selectedShipments}
                    onSelect={onSelect}
                    onUpdate={handleUpdate}
                    onOpen={onOpen}
                    onInvoice={onInvoice}
                    onCreateTrip={onCreateTrip}
                  />
                  {isMenuShowing && (
                    <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
                      <ArrowRightCircle size={18} />
                    </div>
                  )}
                </Stack>
              </td>
            )
          }
          return (
            <td style={{ width: column.width }} key={column.key}>
              <ShipmentCell
                key={column.key}
                column={column}
                shipment={shipment}
                selectedShipments={selectedShipments}
                onSelect={onSelect}
                onUpdate={handleUpdate}
                onOpen={onOpen}
                onInvoice={onInvoice}
                onCreateTrip={onCreateTrip}
              />
            </td>
          )
        })}
      </tr>
    </>
  )
}

interface Props {
  shipment: ShipmentListView;
  onUpdate: (shipmentId: string, key: string, value: any) => void;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  onOpen: (shipmentId: string) => void;
  onInvoice: (shipmentId: string) => void;
  onCreateTrip: (shipmentId: string) => void;
  selectedShipments: string[];
  columns: TripTableColumns[];
}

function ShipmentRow({
  shipment, onUpdate, onSelect, columns, selectedShipments, onOpen, onInvoice, onCreateTrip
}: Props) {
  const entity = useSelector((state: any) => selectShipmentEntity(state, shipment.id));
  return (
    <OtherShipmentRow
      shipment={shipment}
      data={entity}
      onUpdate={onUpdate}
      onSelect={onSelect}
      columns={columns}
      selectedShipments={selectedShipments}
      onOpen={onOpen}
      onInvoice={onInvoice}
      onCreateTrip={onCreateTrip}
    />
  )
}

export default ShipmentRow;
