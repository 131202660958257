import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Col, Card, Badge } from "react-bootstrap"
import { Address, AssetLocation } from "../../../redux/models/settings.models"
import { EntityContainer } from "../../../redux/models/core.models"
import { selectAddressesObject } from "../../../redux/slices/settings"
import AssetLocationApi from "../../../services/asset-location/AssetLocationApi"
import { LocationType } from "../models"
import ButtonSpinner from "../../../components/shared/ButtonSpinner"
import Analytics from "../../../utils/analytics"
import { DateService } from "../../../utils/dateService"
import { formatEnumName } from "../asset-tracker.utils"

const statusColors: EntityContainer<string> = {
  active: "success",
  maintenance: "warning",
  inactive: "danger",
}

interface Props {
  address: Address;
  locationType: string;
}

export default function AssetTrackerCol({ address, locationType }: Props) {
  const addressId = address.entity_id;
  const { orgCode, boardId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const addresses = useSelector(selectAddressesObject);
  const [locations, setLocations] = useState<AssetLocation[]>([]);
  const lat = address.data.samsara_latitude;
  const lng = address.data.samsara_longitude;
  const renderAssetCard = (asset: AssetLocation) => {
    const status = asset.data?.status || '';
    const type = asset.data?.type || '';
    const lastMoveAddressId = asset?.data.last_move?.destination_id || '';
    const lastMoveAddr = addresses[lastMoveAddressId]?.data;
    const lastMoveName = lastMoveAddr?.samsara_name || '';
    const lastMoveAction = asset?.data.last_move?.action || '';
    const lastMoveTime = asset?.data.last_move?.scheduled_arrival_time || '';
    const lastLocationName = asset?.data.last_location?.location || '';
    const lastUpdate = asset?.data.last_location?.timeMs;
    return (
      <Card key={asset.entity_id} className="mb-3">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Card.Title className="h6 mb-0">{asset.data.name || ''}</Card.Title>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            {asset?.data.last_move && (
              <span className="text-muted small">
                {`Last Move: ${lastMoveAction} ${lastMoveName} ${DateService.getDoMMMHHmmFormat(lastMoveTime)}`}
              </span>
            )}
            {status !== '' && (
              <Badge bg={statusColors[status]} pill>
                {formatEnumName(status)}
              </Badge>
            )}
            {type !== '' && (
              <Badge bg="secondary" pill>
                {formatEnumName(type)}
              </Badge>
            )}
          </div>
          <Card.Text className="text-muted small mb-0">{`Last Location: ${lastLocationName}`}</Card.Text>
          {lastUpdate && (
            <Card.Text
              className="text-muted small mb-0"
            >
              Last updated: {DateService.toRelativeTime(lastUpdate)}
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    )
  }
  useEffect(() => {
    // load by last locations
    const fetchLastMove = async () => {
      try {
        setIsLoading(true);
        setLocations([]);
        const response = await AssetLocationApi.getAssetsByAddress(addressId, 0);
        if (response.status === 200) {
          setLocations(response.data.data.items);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchLastLocation = async () => {
      if (lat === 0 && lng === 0) {
        return;
      }
      try {
        setLocations([]);
        setIsLoading(true);
        const response = await AssetLocationApi.getAssetsByLastLocation(lat, lng, 0);
        if (response.status === 200) {
          setLocations(response.data.data.items);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (addressId && locationType === LocationType.lastMove) fetchLastMove();
    if (addressId && locationType === LocationType.lastLocation) fetchLastLocation();
  }, [addressId, locationType, lat, lng]);
  return (
    <Col key={address.entity_id} xs={12} md={6} lg={4} xl={3} className="mb-4">
      <Link to={`/${orgCode}/b/assets/${boardId}/a/${address.entity_id}`}>
        <h3 className="h5 mb-3">{address.data.samsara_name || ''}</h3>
      </Link>
      <div className="d-flex flex-column gap-3">
        {isLoading && <ButtonSpinner />}
        {!isLoading && locations.map((loc) => renderAssetCard(loc))}
      </div>
    </Col>
  )
}