import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom";
import {
  getSettingByName, selectAddressesObject, selectBoardsObject, selectFleetObject
} from "../../../redux/slices/settings"
import PageFooterButtons from "../../../components/shared/PageFooterBtns";
import BreadCrumbs from "../../../components/shared/breadcrumbs/BreadCrumbs";
import { FLEET_TYPES } from "../../../redux/models/settings.models";
import { Api } from "../../../services/services";
import Analytics from "../../../utils/analytics";
import { errorToast, successToast } from "../../../components/notifications/app-toast";
import { formatEnumName, getFleetDetailRoutes } from "../asset-tracker.utils";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { SETTINGS_NAMES } from "../../../constants/settings-manager";

export default function FleetDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode, boardId, addressId, fleetId } = useParams();
  const boards = useSelector(selectBoardsObject);
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const address = addresses[addressId || ''];
  const board = boards[boardId || ''];
  const asset = fleet[fleetId || ''];
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(asset?.data?.status || '');
  const [type, setType] = useState(asset?.data?.type || '');
  const name = asset?.data.name || 'No name';
  const handleCancel = () => navigate(-1);
  const handleSave = async () => {
    if (!asset) return;
    try {
      setIsSaving(true);
      const updates = {
        ...asset,
        data: {
          ...asset.data,
          status,
          type,
        }
      };
      const response = await Api.Fleet.update(updates);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.FLEET, false));
        successToast(`${name} successfully updated`);
        handleCancel();
      }
    } catch (error) {
      Analytics.capture(error);
      errorToast(`Error updating ${name}`);
    }
  };
  useEffect(() => {
    const stat = asset?.data?.status || '';
    const typ = asset?.data?.type || '';
    setStatus(stat);
    setType(typ);
  }, [asset]);
  return (
    <div>
      {board && address && asset && <BreadCrumbs routes={getFleetDetailRoutes(orgCode, board, address, asset)} />}
      <h3>{`${name}`}</h3>
      <Row>
        <Form.Group className="mb-3">
          <Col xs={12} md={3} className="mb-3 mb-md-0">
            <Form.Label>Status</Form.Label>
            <Form.Select title="Status" value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">Select</option>
              <option value="active">Active</option>
              <option value="maintenance">Maintenance</option>
              <option value="in-active">Inactive</option>
            </Form.Select>
          </Col>
          <Col xs={12} md={3} className="mt-3 mb-3 mb-md-0">
            <Form.Label>Trailer Type</Form.Label>
            <Form.Select value={type} onChange={(e) => setType(e.target.value)}>
              <option value="">Select</option>
              {FLEET_TYPES.map((ty) => (
                <option key={ty} value={ty}>{formatEnumName(ty)}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
      </Row>
      <Row>
        <PageFooterButtons
          disabled={isSaving}
          page="FleetDetails"
          deleteButton={false}
          handleSave={handleSave}
          handleClose={handleCancel}
          handleDelete={() => {}}
          isSaving={isSaving}
          isEditable
        />
      </Row>
            
    </div>
  )
}