import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown } from "react-bootstrap";
import { MoreHorizontal } from "react-feather";
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";
import { Api } from "../../../../services/services";
import Analytics from "../../../../utils/analytics";
import { Shipment } from "../../../../redux/models/shipment.models";
import { DateService } from "../../../../utils/dateService";
import ShipmentFeedItem from "./ShipmentFeedItem";

const getDefaultDate = (days: number) => {
  const today = new Date();
  return DateService.subtractDaysFromDateISO(today, days);
};

function UninvoicedShipments() {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [days, setDays] = useState(7);
  const isNextPage = shipments.length < total;
  const loadNextPage = async () => {
    try {
      const date = getDefaultDate(days);
      const response = await Api.Dashboard.shipmentsUninvoiced(date);
      if (response.status === 200) {
        const shipmentData = response.data.data.items || [];
        setPage(page + 1);
        setShipments([...shipments, ...shipmentData]);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  }
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.shipmentsUninvoiced(getDefaultDate(days));
      if (response.status === 200) {
        const shipmentData = response.data.data.items || [];
        const count = response.data.data.total_count || 0;
        setTotal(count);
        setShipments(shipmentData);
      }
      setIsLoading(false);
    };
    load();
  }, [days]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setDays(7)}>Last 7 Days</Dropdown.Item>
              <Dropdown.Item onClick={() => setDays(15)}>Last 15 Days</Dropdown.Item>
              <Dropdown.Item onClick={() => setDays(30)}>Last 30 Days</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">{`Un Invoiced Shipments Last ${days} Days`}</Card.Title>
      </Card.Header>
      <Card.Body>
        {isLoading && <ButtonSpinner />}
        {!isLoading && shipments.length === 0 &&  (
          <small className="text-muted">{`No uninvoiced shipments in the last ${days} days`}</small>
        )}
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {!isLoading && shipments.map((shipment) => {
            return <ShipmentFeedItem key={shipment.entity_id} shipment={shipment} />
          })}
        </div>
        {isNextPage && (
          <div className="d-grid">
            <Button variant="primary" onClick={loadNextPage}>Load more</Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default UninvoicedShipments;
