/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button, Container, Navbar, Nav, Stack } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { completeSharedTrip, confirmSharedTrip, loadSharedTrip } from '../../redux/slices/trips/trips';
import { Move, TripDetails, TripStatus } from '../../redux/models/trip.models';
import { EntityContainer } from '../../redux/models/core.models';
import { Address } from '../../redux/models/settings.models';
import ShareTripHeader from './components/ShareTripHeader';
import ShareTripMoves from './components/ShareTripMoves';
// import ShareTripShipments from './components/ShareTripShipments';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import { Shipment } from '../../redux/models/shipment.models';
import Analytics from '../../utils/analytics';
import { updateShipment } from '../../redux/slices/shipments';
import ConfirmModal from '../../components/modals/ConfirmModal';
import useAuth from '../../hooks/useAuth';

const getBtnTitle = (status: string) => {
  if (status === TripStatus.DriverConfirmed) {
    return 'Complete';
  }
  if (status === TripStatus.Complete) {
    return 'Completed';
  }
  return 'Confirm Dispatch';
};

const getBtnVariant = (status: string) => {
  if (status === TripStatus.DriverConfirmed) {
    return 'success';
  }
  return 'primary';
};

function NavBar({ title }: { title: string; }) {
  return (
    <Navbar style={{ background: 'white' }}>
      <Container>
        <Navbar.Brand href="#home">{title}</Navbar.Brand>
        <Nav className="me-auto">
          {/* <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#features">Features</Nav.Link>
          <Nav.Link href="#pricing">Pricing</Nav.Link> */}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default function ShareTripDetails() {
  const { apiKey } = useAuth();
  const { id, orgCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<EntityContainer<Address>>({});
  const [trip, setTrip] = useState<TripDetails | null>(null);
  const [moves, setMoves] = useState<Move[]>([]);
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const status = trip?.data.status || '';
  const btnTitle = getBtnTitle(status);
  const btnVariant = getBtnVariant(status);
  const token = apiKey;
  const isBtnHidden = status === TripStatus.Complete || status === TripStatus.DriverConfirmed;
  const handleConfirm = async () => {
    if (!id || !token || !orgCode) return;
    try {
      setIsSaving(true);
      const response = await confirmSharedTrip(id, token, orgCode);
      if (response.status === 200) {
        const tripData: TripDetails = response.data.data;
        // const shipmentData = tripData?.data.shipments || [];
        setTrip(tripData);
        // setShipments(shipmentData);
        setIsConfirmOpen(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
      
    }
  }
  const handleComplete = async () => {
    if (!id || !token || !orgCode) return;
    try {
      setIsSaving(true);
      const response = await completeSharedTrip(id, token, orgCode);
      if (response.status === 200) {
        const tripData = response.data.data;
        const shipmentData = tripData?.data.shipments || [];
        setTrip(tripData);
        setShipments(shipmentData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
    }
  }

  const handleConfirmDispatch = () => {
    setIsConfirmOpen(true);
  }

  const handleBtn = async () => {
    if (status === TripStatus.DriverConfirmed) {
      handleComplete();
    } else {
      handleConfirm();
    }
  };

  const saveShipment = async (shipment: Shipment) => {
    if (!trip) return;
    try {
      const response = await updateShipment(shipment);
      if (response.status === 200) {
        const shipData = response.data.data;
        const tripShips = trip.data.shipments || [];
        const newShips = tripShips.map((ship) => {
          if (ship.entity_id === shipData.entity_id) {
            return shipData;
          }
          return ship;
        });
        const nwTrip = {
          ...trip,
          data: {
            ...trip.data,
            shipments: newShips,
          }
        };
        setTrip(nwTrip);
        setShipments(newShips);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  }
  useEffect(() => {
    const load = async () => {
      if (!id || !orgCode || !token || token === '') return;
      try {
        setIsLoading(true);
        const response = await loadSharedTrip(id, token, orgCode);
        if (response.status === 200) {
          const data = response.data.data || {};
          const addressData = data.addresses || [];
          const tripData = data.trip;
          const tripShips = tripData?.data.shipments || [];
          const addrContainer = addressData.reduce((store: EntityContainer<Address>, addr: Address) => {
            return {
              ...store,
              [addr.entity_id]: addr,
            };
          }, {});
          const moveData = tripData?.data.moves || [];
          setAddresses(addrContainer);
          setTrip(tripData);
          setMoves(moveData);
          setShipments(tripShips);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [id, orgCode, token]);
  return (
    <>
      <Helmet title="Trip" />
      {trip && <NavBar title={trip.data.trip_no} />}
      <Container fluid className="p-0 pb-4">
          {isLoading ? <Loader /> : null}
          {!isLoading && trip ? (
            <>
              <ShareTripHeader trip={trip} />
              <div>
                <p className="px-4 pt-4 text-muted">Moves</p>
                <ShareTripMoves addresses={addresses} moves={moves} />
                {/* <ShareTripShipments shipments={shipments} saveShipment={saveShipment} /> */}
              </div>
              <Stack className="mt-4 mb-5">
                <Button hidden={isBtnHidden} variant={btnVariant} onClick={handleConfirmDispatch} disabled={isSaving}>
                  {isSaving && <ButtonSpinner />}
                  {!isSaving && btnTitle}
                </Button>
              </Stack>
            </>
          ) : null}
        {isConfirmOpen && (
          <ConfirmModal
            title="Confirm Trip"
            details="Would you like to confirm your trip with dispatch?"
            btnTitle="Confirm"
            saveDisabled={isSaving}
            shouldShowModal={isConfirmOpen}
            isSaving={isSaving}
            handleSave={handleConfirm}
            cancelSave={() => setIsConfirmOpen(false)}
          />
        )}
      </Container>
    </>
  );
}
