import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Row, Col, Form, Card, Badge } from "react-bootstrap"
import { EntityContainer } from "../../../redux/models/core.models"
import { AssetLocation } from "../../../redux/models/settings.models"
import { Api } from "../../../services/services"
import Analytics from "../../../utils/analytics"
import { LocationType } from "../models"
import { selectAddressesObject, selectBoardsObject } from "../../../redux/slices/settings"
import ButtonSpinner from "../../../components/shared/ButtonSpinner"
import { DateService } from "../../../utils/dateService"
import BreadCrumbs from "../../../components/shared/breadcrumbs/BreadCrumbs"
import { formatEnumName, getAddressRoutes } from "../asset-tracker.utils"

const statusColors: EntityContainer<string> = {
  active: "success",
  maintenance: "warning",
  inactive: "danger",
}

export default function AssetTrackerAddress() {
  const { orgCode, boardId, addressId } = useParams();
  const boards = useSelector(selectBoardsObject);
  const addresses = useSelector(selectAddressesObject);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState<AssetLocation[]>([]);
  const [locationType, setLocationType] = useState(LocationType.lastMove);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter] = useState("all");
  // const [typeFilter, setTypeFilter] = useState("all");
  const address = addresses[addressId || ''];
  const board = boards[boardId || ''];
  const lat = address?.data.samsara_latitude;
  const lng = address?.data.samsara_longitude;
  const name = address?.data.samsara_name || 'No name';
  const filteredAssets = locations.filter((location) => {
    const matchesSearch = location.data.name?.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesStatus = statusFilter === "all" || location.data?.status === statusFilter
    // const matchesType = typeFilter === "all" || location.data?.type === typeFilter
    return matchesSearch && matchesStatus;
  })

  useEffect(() => {
    // load by last locations
    const fetchLastMove = async (id: string) => {
      try {
        setIsLoading(true);
        setLocations([]);
        const response = await Api.AssetLocation.getAssetsByAddress(id, null);
        if (response.status === 200) {
          setLocations(response.data.data);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchLastLocation = async () => {
      if (lat === 0 && lng === 0) {
        return;
      }
      try {
        setLocations([]);
        setIsLoading(true);
        const response = await Api.AssetLocation.getAssetsByLastLocation(lat, lng, null);
        if (response.status === 200) {
          setLocations(response.data.data);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (addressId && locationType === LocationType.lastMove) fetchLastMove(addressId);
    if (addressId && locationType === LocationType.lastLocation) fetchLastLocation();
  }, [addressId, locationType, lat, lng]);
  return (
    <div className="min-vh-100">
      {board && address && <BreadCrumbs routes={getAddressRoutes(orgCode, board, address)} />}
      <h3>{name}</h3>
      <Row className="mb-4">
        <Col xs={12} md={6} className="mb-3 mb-md-0">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search assets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </Col>
        {/* <Col xs={12} md={3} className="mb-3 mb-md-0">
          <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="all">All Statuses</option>
            <option value="active">Active</option>
            <option value="maintenance">Maintenance</option>
            <option value="inactive">Inactive</option>
          </Form.Select>
        </Col>
        <Col xs={12} md={3}>
          <Form.Select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
            <option value="all">All Types</option>
            <option value="truck">Truck</option>
            <option value="car">Car</option>
            <option value="motorcycle">Motorcycle</option>
          </Form.Select>
        </Col> */}
        <Col xs={12} md={6} lg={4}>
          <Form.Select value={locationType} onChange={(e) => setLocationType(e.target.value)}>
            <option value={LocationType.lastMove}>Load Last Move</option>
            <option value={LocationType.lastLocation}>Load Last Location</option>
          </Form.Select>
        </Col>
      </Row>

      <Row xs={1} md={2} lg={3} className="g-4">
        {isLoading && <ButtonSpinner />}
        {!isLoading && filteredAssets.map((asset) => {
          const status = asset.data.status || '';
          const type = asset.data.type || '';
          return (
            <Col key={asset.entity_id}>
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Link
                        to={`/${orgCode}/b/assets/${boardId}/a/${address.entity_id}/f/${asset.entity_id}`}
                      >
                      <Card.Title className="h6 mb-0">
                        {asset.data.name || ''}
                      </Card.Title>
                    </Link>
                    {status !== '' && (
                      <Badge bg={statusColors[asset.data.status || '']} pill>
                        {formatEnumName(status)}
                      </Badge>
                    )}
                    {type !== '' && (
                      <Badge bg={statusColors[asset.data.status || '']} pill>
                        {formatEnumName(type)}
                      </Badge>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="text-muted small">{asset.data.last_location?.location || ''}</span>
                  </div>
                  <Card.Text className="text-muted small mb-0">
                    {`Last updated: ${DateService.toRelativeTime(asset.data.last_location?.timeMs)}`}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
      
      {filteredAssets.length === 0 && (
        <p className="text-center text-muted mt-5">No assets found matching the current filters.</p>
      )}

    </div>
  )
}