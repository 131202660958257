import { AssetLocationData } from '../../redux/models/settings.models';
import axiosInstance from '../../utils/axios';
import { EntityServiceProvider } from '../base/EntityServiceProvider';

class AssetLocationApiProvider<T> extends EntityServiceProvider<T> {
  getAssetsByAddress(addressId: string, page: number | null) {
    if (page !== null && page !== undefined) {
      const data = {
        "page": page,
        "page_size": 10,
      };
      return this.api.post(`${this.endpoint.entityPath()}/address/last-move/${addressId}`, data);
    }
    return this.api.post(`${this.endpoint.entityPath()}/address/last-move/${addressId}`);
  }

  getAssetsByLastLocation(lat: number, lng: number, page: number | null) {
    const data: Record<string, number> = {
      "lat": lat,
      "lng": lng
    };
    if (page !== null && page !== undefined) {
      data.page = page;
      data.page_size = 10
    }
    return this.api.post(`${this.endpoint.entityPath()}/address/last-location`, data);
  }
}

const AssetLocationApi = new AssetLocationApiProvider<AssetLocationData>('asset-location', axiosInstance);

export default AssetLocationApi;