import { Board } from "../../redux/models/board.models";
import { Address, Fleet } from "../../redux/models/settings.models";
import { BreadCrumbRoute } from "../../components/shared/breadcrumbs/BreadCrumbs";

export const formatEnumName = (input: string) => {
  return input
    .split('-') // Split the string by hyphens
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(' '); // Join the words with spaces
}


export const getAddressRoutes = (orgCode: string | undefined, board: Board, address: Address): BreadCrumbRoute[] => {
  const routes = [
    {
      name: `${board.data.name}`,
      route: `/${orgCode || ''}/b/assets/${board.entity_id}`,
    },
    {
      name: `${address.data.samsara_name}`,
      route: `/${orgCode}/b/assets/${board.entity_id}/a/${address.entity_id}`,
    },
  ];
  return routes;
};

export const getFleetDetailRoutes = (
  orgCode: string | undefined, board: Board, address: Address, fleet: Fleet
): BreadCrumbRoute[] => {
  const routes = [
    ...getAddressRoutes(orgCode, board, address),
    {
      name: `${fleet.data.name}`,
      route: `/${orgCode}/b/assets/${board.entity_id}/a/${address.entity_id}/f/${fleet.entity_id}`,
    },
  ];
  return routes;
};