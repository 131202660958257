/* eslint-disable import/no-cycle */
import { EntityContainer } from '../redux/models/core.models';

export const FILTER_STORAGE_PREFIX = 'dashboard-';
export const SETTINGS_STORAGE_PREFIX = 'settings-';
export const REFRESH_SETTINGS_HOURS = 10;

export const SETTINGS_NAMES = {
  ADDRESSES: 'addresses',
  BOARDS: 'boards',
  GL_CODES: 'gl_codes',
  GROUPS: 'groups',
  CONTACTS: 'contacts',
  COSTS: 'costs',
  NOTE_TEMPLATES: 'note_templates',
  DRIVERS: 'drivers',
  VEHICLES: 'vehicles',
  FLEET: 'fleets',
  BILLING_ITEMS: 'billing_items',
  BILLING_TYPES: 'billing_types',
  UOMS: 'uoms',
  PRODUCTS: 'products',
  SHIPMENT_TYPES: 'shipment_types',
  SHIPPING_LINES: 'shipping_lines',
  TAGS: 'tags',
  TRIP_TEMPLATES: 'trip_templates',
};

export const getSettingName = (key: string) => {
  const names: EntityContainer<string> = {
    addresses: 'Addresses',
    boards: 'Boards',
    gl_codes: 'GL Codes',
    groups: 'Groups',
    contacts: 'Contacts',
    costs: 'Costs',
    note_templates: 'Note Templates',
    drivers: 'Drivers',
    vehicles: 'Vehicles',
    fleets: 'Fleet',
    billing_items: 'Rates',
    billing_types: 'Quote Templates',
    uoms: 'Units Of Measure',
    products: 'Products',
    shipment_types: 'Shipment Types',
    shipping_lines: 'Shipping Lines',
    tags: 'Tags',
    trip_templates: 'Trip Templates',
  };
  return names[key] || key;
}

