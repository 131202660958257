import React from 'react';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import { Move } from '../../../redux/models/trip.models';
import { DateService } from '../../../utils/dateService';
import { EntityContainer } from '../../../redux/models/core.models';
import { Address } from '../../../redux/models/settings.models';

interface Props {
  addresses: EntityContainer<Address>;
  moves: Move[];
}
export default function ShareTripMoves({ addresses, moves }: Props) {
  const positioned = [...moves].sort((a, b) => {
    const aPos = a.data.position || 0;
    const bPos = b.data.position || 0;
    return aPos - bPos;
  });
  const handleDirections = (address: string) => {
    if (address === '') return;
    const addrStr = address.replace(' ', '+');
    const url = `https://www.google.com/maps?q=${addrStr}`
    window.open(url);
  };
  return (
    <Accordion defaultActiveKey="0"  style={{ background: '#fff' }}>
      {positioned.map((move, idx) => {
        const destinationId = move.data.destination_id || '';
        const notes = move.data.samsara_notes || '';
        const destination = addresses[destinationId] || {};
        const destinationName = destination?.data?.samsara_name || '';
        const address = destination?.data?.samsara_formatted_address || '';
        const moveStart = move.data.scheduled_arrival_time || '';
        const moveEnd = move.data.scheduled_arrival_time || '';
        const arrival = moveStart !== '' ? DateService.getYYYYMMDDhhmmFormat(moveStart) : 'No appointment set';
        const dep = moveEnd !== '' ? DateService.getHHmmaFormat(moveEnd) : 'No departure set';
        const moveApt = moveStart !== '' ? DateService.getHHmmaFormat(moveStart) : 'No appointment set';
        return (
          <Accordion.Item key={move.entity_id} eventKey={`${idx}`}>
            <Accordion.Header>{`${idx + 1}. ${destinationName} ${arrival}`}</Accordion.Header>
            <Accordion.Body style={{ background: '#fff' }}>
              <div>
                <h5>{destinationName}</h5>
                <p>{address}</p>
                <Row>
                  <Col>
                    <h6>Arrive at</h6>
                    <p>{moveApt || ''}</p>
                  </Col>
                  <Col>
                    <h6>Depart at</h6>
                    <p>{dep || ''}</p>
                  </Col>
                </Row>
              </div>
              <h6>Notes</h6>
              <p>{notes}</p>
              <Button onClick={() => handleDirections(address)}>Directions</Button>
            </Accordion.Body>
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}
