/* eslint-disable max-len */
/* eslint-disable */
import {
  createContext, useEffect, useMemo, useReducer,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Auth0Client } from '@auth0/auth0-spa-js';
import jwtDecode from "jwt-decode";

import {
  auth0Config,
  LOCAL_STORAGE_TOKEN_KEY,
  setOrgCode,
  LOCAL_STORAGE_MAGIC_LINK,
  LOCAL_STORAGE_AUTH_TYPE
} from '../config';
import Analytics from '../utils/analytics';
import { DateService } from '../utils/dateService';
// import { isInitialized } from '@sentry/react';

const INITIALIZE = 'INITIALIZE';
const START_AUTH_REDIRECT = 'START_AUTH_REDIRECT';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const AUTH_TYPE = {
  AUTH_0: 'auth0',
  MAGIC_LINK: 'magic-link',
}
const aquadashLogo = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/640b6ff7561700f7f0bddbfb_AquaDash-transparent.png';
const logo = process.env.REACT_APP_LOGO_URL || aquadashLogo;
const title = process.env.REACT_APP_LOGIN_TITLE || 'Welcome to AquaDash';
const description = process.env.REACT_APP_LOGIN_DESCRIPTION || 'Sign in to continue';

const saveToken = (token) => {
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  window.localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AUTH_TYPE.AUTH_0);
};

const saveMagicLink = (magicLink) => {
  window.localStorage.setItem(LOCAL_STORAGE_MAGIC_LINK, magicLink);
  window.localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AUTH_TYPE.MAGIC_LINK);
}

export const isTokenValid = (token) => {
  if (!token) return false;
  const decoded = jwtDecode(token);
  const exp = decoded?.exp || null;
  if (exp) {
    const isFuture = DateService.isFutureUnix(exp);
    return isFuture
  }
  return false;
}

export const auth0Client = new Auth0Client({
  clientId: auth0Config.clientId || '',
  domain: auth0Config.domain || '',
  authorizationParams:{
    audience: auth0Config.audience || '',
    redirect_uri: window.location.origin,
  },
  cacheLocation: 'localstorage',
  logo_url: logo,
  page_title: title,
  page_description: description,
});

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  state: '',
  authType: AUTH_TYPE.AUTH_0,
  apiKey: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const payload = action.payload;
    return {
      ...state,
      ...payload,
      isInitialized: true,
    };
  }
  if (action.type === START_AUTH_REDIRECT) {
    const { isLoggingIn } = action.payload;
    return {
      ...state,
      isLoggingIn,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, isLoggingIn: false, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const initMagicLink = async (magicLink) => {
      // load and validate magic link
      saveMagicLink(magicLink);
      dispatch({
        type: INITIALIZE,
        payload: {
          ...initialState,
          authType: AUTH_TYPE.MAGIC_LINK,
          apiKey: magicLink,
          isAuthenticated: true,
          state: 'success'
        },
      });
    };
    const initAuth0 = async () => {
      try {
        await auth0Client.checkSession();
        const isAuthenticated = await auth0Client.isAuthenticated();
        const token = await auth0Client.getTokenSilently();
        saveToken(token);
        const isAuthed = isTokenValid(token);
        if (token && isAuthenticated) {
          const user = await auth0Client.getUser();
          const userId = user?.email;
          const orgs = user?.orgs || [];
          if (orgs.length === 1) {
            const org = orgs[0];
            setOrgCode(org);
          };
          if (userId) Analytics.identify(userId);
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: true, user: user || null, state: 'success' },
          });
        } else {
          console.log('invalid token and not authed. Check for token in url query string');
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null, state: 'invalid' },
          });
          navigate('/');
        }
      } catch (err) {
        console.log('error authenticating');
        console.log(err);
        console.log(err.message);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null, state: 'error' },
        });
        navigate('/');
      }
    };
    
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    if (token) {
      initMagicLink(token);
    } else {
      initAuth0();
    }
  }, [location]);

  const signIn = async () => {
    dispatch({
      type: START_AUTH_REDIRECT,
      payload: { isLoggingIn: true },
    });
    const loginRedirect = await auth0Client?.loginWithRedirect({
      appState: {
        goToProjects: true, // state to restore when getting redirected back
      }
    });
    return loginRedirect;
  };

  /// https://community.auth0.com/t/angular-10-spa-error-on-redirect-after-login-there-are-no-query-params-available-for-parsing/57805/5
  const onloadLogin = async () => {
    try {
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: true },
      });
      const redirectResult = await auth0Client.handleRedirectCallback();
      const isAuthenticated = await auth0Client?.isAuthenticated();
      dispatch({
        type: START_AUTH_REDIRECT,
        payload: { isLoggingIn: false },
      });
      if (isAuthenticated) {
        const user = await auth0Client?.getUser();
        const token = await auth0Client.getTokenSilently();
        saveToken(token);
        const userId = user?.email;
        if (userId) Analytics.identify(userId);
        dispatch({ type: SIGN_IN, payload: { user: user || null } });
        window.removeEventListener('load', onloadLogin);
        // window.location.replace(`${window.location.origin}/projects`);
        return redirectResult;
      }
      return window.removeEventListener('load', onloadLogin);
    } catch (error) {
      Analytics.capture(error);
      return {};
    }
  };

  if (window.location.href.includes('code')) window.addEventListener('load', onloadLogin);

  const signOut = () => {
    auth0Client?.logout({ openUrl: false });
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = () => {};

  const providerValue = useMemo(
    () => ({
      ...state,
      method: 'auth0',
      isInitialized: state?.isInitialized,
      apiKey: state?.apiKey,
      authType: state?.authType,
      user: {
        id: state?.user?.sub,
        avatar: state?.user?.picture,
        email: state?.user?.email,
        displayName: state?.user?.nickname,
        role: 'user',
        orgs: state?.user?.orgs ?? [],
      },
      signIn,
      signOut,
      resetPassword,
    }),
    [state],
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };