export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  cacheLocation: 'localstorage',
};

export const LOCAL_STORAGE_TOKEN_KEY = 'accessToken';
export const LOCAL_STORAGE_MAGIC_LINK = 'magicLink';
export const LOCAL_STORAGE_AUTH_TYPE = 'authType';
export const LOCAL_STORAGE_ORG_CODE = 'orgCode';
export const LOCAL_STORAGE_PERMISSIONS = 'permissions';

export const setOrgCode = (org: string) => {
  window.localStorage.setItem(LOCAL_STORAGE_ORG_CODE, org);
}

export const getOrgCode = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_ORG_CODE);
}

export const setPermissions = (permissions: string[]) => {
  const value = JSON.stringify(permissions);
  window.localStorage.setItem(LOCAL_STORAGE_PERMISSIONS, value);
}

export const getPermissions = (): string[] => {
  const value = window.localStorage.getItem(LOCAL_STORAGE_PERMISSIONS);
  return value ? JSON.parse(value) : [];
}
